<template>
  <b-list-group>
    <b-list-group-item>
      <div class="font-weight-bold text-primary">
        Adı Soyadı
      </div>
      <div>
        {{ getCarCard.customer }}
      </div>
    </b-list-group-item>
    <b-list-group-item>
      <div class="font-weight-bold text-primary">
        Telefon
      </div>
      <div>
        {{ getCarCard.phone }}
      </div>
    </b-list-group-item>
    <b-list-group-item>
      <div class="font-weight-bold text-primary">
        E-Posta
      </div>
      <div>
        {{ getCarCard.email }}
      </div>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import { BListGroup, BListGroupItem } from 'bootstrap-vue'

export default {
  name: 'CustomerCard',
  components: {
    BListGroup,
    BListGroupItem,
  },
  computed: {
    getCarCard() {
      return this.$store.getters['saleSurveys/getCarCard']
    },
  },
}
</script>
