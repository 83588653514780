<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <b-card-header>
            <b-card-title>Sıfır Satış Anketler</b-card-title>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col>
                <b-form-group
                  label="Marka"
                  label-for="brand"
                >
                  <v-select
                    id="brand"
                    v-model="filterData.id_com_brand"
                    label="title"
                    :reduce="brand => brand.id"
                    :options="brands"
                    placeholder="Seçiniz"
                    @input="filterMethod"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label="Danışman"
                  label-for="id_com_user"
                >
                  <v-select
                    id="brand"
                    v-model="filterData.id_com_user"
                    label="title"
                    :reduce="user => user.id"
                    :options="users"
                    placeholder="Seçiniz"
                    @input="filterMethod"
                  >
                    <template v-slot:option="option">
                      <div v-if="option.brand">
                        {{ option.title }}
                        <div>
                          <small class="text-warning">{{ option.brand }} / {{ option.user_type }}</small>
                        </div>
                      </div>
                      <div v-else>
                        {{ option.title }}
                      </div>
                    </template>
                    <template v-slot:selected-option="option">
                      <div v-if="option.brand">
                        {{ option.title }}
                        <small class="text-warning">{{ option.brand }}</small>
                      </div>
                      <div v-else>
                        {{ option.title }}
                      </div>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
          <template v-if="dataList.length > 0">
            <b-table
              responsive="sm"
              :fields="fields"
              :items="dataList"
              striped
              hover
            >
              <template #cell(customer)="data">
                <b-link
                  :to="'/i2sales/view/' + data.item.id"
                  target="_blank"
                >
                  <div class="text-body">
                    {{ data.item.customer }}
                  </div>
                  <div
                    v-if="data.item.company_name"
                    class="font-small-2 text-muted"
                  >
                    {{ data.item.company_name }}
                  </div>
                </b-link>
              </template>
              <template #cell(company_name)="data">
                {{ data.item.company_name }}
              </template>
              <template #cell(phone)="data">
                {{ data.item.phone }}
              </template>
              <template #cell(username)="data">
                {{ data.item.username }}
              </template>

              <template #cell(license_plate)="data">
                {{ data.item.brand }} {{ data.item.model }}

                <div class="text-warning font-weight-bold font-small-2">
                  {{ data.item.license_plate }}
                </div>
              </template>
              <template #cell(ddate)="data">
                {{ moment(data.item.invoice_date).format('DD.MM.YYYY') }}
                <div class="text-danger font-weight-bold font-small-2">
                  {{ moment().diff(moment(data.item.invoice_date),'days') }} gün önce
                </div>
              </template>
              <template #cell(control)="data">
                <b-button
                  size="sm"
                  variant="outline-primary"
                  @click="setModalData(data.item)"
                >
                  <FeatherIcon icon="PieChartIcon" />
                  Anket Oluştur
                </b-button>
              </template>

            </b-table>
            <b-card-footer>
              <b-pagination
                v-model="currentPage"
                :total-rows="dataCount"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-card-footer>
          </template>
          <template v-else>
            <b-alert
              show
              variant="info"
              class="m-2"
            >
              <div class="alert-body text-center">
                Hiç bekleyen anketiniz yok. 👏
              </div>
            </b-alert>
          </template>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      v-model="getModalStatus.status"
      centered
      title="Sıfır Satış 5. Gün Anketi"
      size="xl"
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
    >
      <survey-form />
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BCardFooter,
  BPagination,
  BButton,
  BCardTitle,
  BCardHeader,
  BLink,
  BModal,
  BAlert,
  BCardBody,
  BFormGroup,
} from 'bootstrap-vue'
import moment from 'moment'
import vSelect from 'vue-select'
import SurveyForm from '@/views/SaleSurveys/SurveyForm.vue'

export default {
  name: 'Index',
  components: {
    BFormGroup,
    vSelect,
    BAlert,
    BCardHeader,
    BCardTitle,
    BCard,
    BTable,
    BRow,
    BCol,
    BCardFooter,
    BPagination,
    BButton,
    BLink,
    BModal,
    SurveyForm,
    BCardBody,
  },
  data() {
    return {

      currentPage: 1,
      perPage: 20,
      filterData: {
        id_com_brand: null,
        id_com_user: null,
      },
      fields: [
        {
          key: 'customer',
          label: 'Müşteri',
          thClass: 'align-middle',
        },
        {
          key: 'phone',
          label: 'Telefon',
          thClass: 'width-200 text-nowrap align-middle',
          tdClass: 'text-nowrap',
        },
        {
          key: 'username',
          label: 'Satış Danışmanı',
          thClass: 'width-200 text-nowrap align-middle',
          tdClass: 'text-nowrap',
        },
        {
          key: 'license_plate',
          label: 'Araç Bilgisi',
          thClass: 'width-200 text-nowrap align-middle',
          tdClass: 'text-nowrap',
        },
        {
          key: 'ddate',
          label: 'Satış Tarihi',
          thClass: 'width-200 text-nowrap align-middle text-center',
          tdClass: 'text-nowrap text-center',
        },
        {
          key: 'control',
          label: '',
          thClass: 'width-100',
          tdClass: 'text-nowrap text-right',
        },
      ],
      dataQuery: {
        select: [
          'com_sale_orderform.id AS id',
          'com_sale_orderform.id_com_customer AS id_com_customer',
          'com_sale_orderform.id_com_brand AS id_com_brand',
          'com_sale_orderform.id_com_user AS id_com_user',
          '_mssql_aracsatis.invoice_date AS invoice_date',
          'com_sale_delivery.id AS id_com_sale_delivery',
          'com_customer.id_com_user AS id_com_user',
          'com_model.title AS model',
          'com_brand.name AS brand',
          'com_user.name AS username',
          'com_customer.name AS customer',
          'com_customer.company_name AS company_name',
          'com_customer.phone AS phone',
          'com_stock.chasis AS chasis',
        ],
        limit: 20,
        start: 0,
      },
      setCarcard: {
        id: null,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['saleSurveys/getSales']
    },
    dataCount() {
      return this.$store.getters['saleSurveys/getSalesCount']
    },
    getCarCard() {
      return this.$store.getters['saleSurveys/getCarCard']
    },
    getInterview() {
      return this.$store.getters['interviews/getInterview']
    },
    getModalStatus() {
      return this.$store.getters['saleSurveys/getModalStatus']
    },
    brands() {
      return this.$store.getters['brands/getBrands']
    },
    users() {
      return this.$store.getters['users/getUsers']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 20
      this.pagination(page)
    },
  },
  created() {
    this.getDataList()
    this.getBrands()
    this.getUsers()
  },
  methods: {
    filterMethod() {
      this.dataQuery.where = {}
      if (this.filterData.id_com_brand) {
        this.dataQuery.where['com_sale_orderform.id_com_brand'] = this.filterData.id_com_brand
      }
      if (this.filterData.id_com_user) {
        this.dataQuery.where['com_sale_orderform.id_com_user'] = this.filterData.id_com_user
      }
      this.getDataList()
    },
    setInterview(data) {
      this.$store.dispatch('interviews/getInterviewNumber')
        .then(response => {
          if (response) {
            this.getInterview.interview_number = response
          }
        })
      this.getInterview.initial = '1'
      this.getInterview.status = '1'
      this.getInterview.content = 'Sıfır satış memnuniyet anketi.'
      this.getInterview.id_com_interview_type = '8'
      this.getInterview.id_com_interview_subject = '16'
      this.getInterview.id_com_sale_delivery = data.id_com_sale_delivery
      this.getInterview.id_com_meet = '2'
      this.getInterview.id_com_cars = null
      this.getInterview.id_com_customer = data.id_com_customer
      this.getInterview.id_com_brand = data.id_com_brand
      this.getInterview.id_com_user1 = data.id_com_user
      this.getInterview.idate = moment().format('YYYY-MM-DD')
      this.getInterview.itime = moment().format('HH:MM')
      this.getInterview.saleSurvey = true
      // this.getInterview.chasis = data.order_number
      // console.log(this.getInterview)
    },

    setModalData(data) {
      this.$store.dispatch('interviews/interviewReset')
      this.setInterview(data)
      this.setCarcard.id_com_customer = data.id_com_customer
      this.setCarcard.chasis = data.chasis
      this.setCarCards(this.setCarcard)
      this.getModalStatus.status = true
    },
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    getDataList() {
      this.$store.dispatch('saleSurveys/Sales', this.dataQuery)
    },
    setCarCards() {
      this.$store.dispatch('saleSurveys/setCarCards', this.setCarcard)
        .then(response => {
          this.getInterview.id_com_cars = response.id_com_cars
          this.getInterview.chasis = response.chasis
        })
    },
    getBrands() {
      this.$store.dispatch('brands/brandsList', {
        select: ['com_brand.id AS id', 'com_brand.name AS title'],
        where: { 'com_brand.salestatus': 1 },
      })
    },
    getUsers() {
      this.$store.dispatch('users/usersList', {
        select: [
          'com_user.id AS id',
          'com_user.name AS title',
          'com_user_type.title as user_type',
          'com_brand.name as brand',
          'com_department.title as department',
        ],
        where: {
          'com_user.status': 1,
          'com_user.id_com_user_type': 5,
          'com_user.id_com_department': 2,
        },
      })
    },
  },
}
</script>
